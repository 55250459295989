<!--
 * @Author: Geroge
 * @Description: 高才申请人信息
-->
<template>
    <div class="user-container common-part">
        <!-- 主申请人信息 -->
        <el-form
            ref="currentItem"
            :model="currentItem"
            :rules="rules"
            label-width="150px"
            class="demo-ruleForm"
        >
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="主申姓名：" prop="username">
                        <el-input
                            v-model="currentItem.username"
                            placeholder="姓名请与身份证一致"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="曾用名：" prop="used_name">
                        <el-input
                            v-model="currentItem.used_name"
                            placeholder="请与户口本保持一致"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="姓名拼音："
                        prop="username_pinyin.family_name"
                        class="pinyin"
                    >
                        <el-input
                            v-model="currentItem.username_pinyin.family_name"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                            placeholder="请输入姓氏拼音"
                        />
                        <el-form-item
                            prop="username_pinyin.given_name"
                            class="add-fields"
                        >
                            <el-input
                                v-model="currentItem.username_pinyin.given_name"
                                clearable
                                auto-complete="off"
                                maxlength="50"
                                placeholder="请输入名称拼音"
                            />
                        </el-form-item>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="性别：" prop="sex">
                        <el-select
                            v-model="currentItem.sex"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option
                                v-for="item in CONFIG.SEX_TYPE"
                                :label="item.name"
                                :value="item.value"
                                :key="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="出生日期：" prop="birthday">
                        <el-date-picker
                            v-model="currentItem.birthday"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="国籍/地区：" prop="nationality">
                        <CountrySelect v-model="currentItem.nationality" />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="出生城市："
                        :prop="`birth_place_aboard`"
                        class="birth_city"
                    >
                        <el-radio-group
                            v-model="currentItem.birth_place_aboard"
                            @change="changeBirthCountry"
                        >
                            <el-radio :label="0">国内</el-radio>
                            <el-radio :label="1">国外</el-radio>
                        </el-radio-group>
                        <el-form-item
                            v-if="currentItem.birth_place_aboard === 0"
                            prop="birth_place.area"
                            class="add-fields"
                        >
                        <el-row :gutter="20">
                            <el-col :span="7">
                                <CitySelect
                                    v-model="currentItem.birth_place.area"
                                    clearable
                                />
                            </el-col>
                            <el-col :span="17">
                                <el-input
                                    v-model="currentItem.birth_place.details"
                                    maxlength="100"
                                    placeholder="请输入详细地址"
                                    clearable
                                    auto-complete="off"
                                />
                            </el-col>
                        </el-row>
                        </el-form-item>
                        <el-form-item
                            v-if="currentItem.birth_place_aboard === 1"
                            prop="birth_place.foreign"
                            class="add-fields"
                        >
                            <el-input
                                v-model="currentItem.birth_place.foreign"
                                maxlength="100"
                                placeholder="请输入"
                                clearable
                                auto-complete="off"
                            />
                        </el-form-item>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="现居住地址："
                        prop="address"
                    >
                        <CurrentAddress
                            ref="CurrentAddress"
                            v-model="currentItem.address"
                            :is_live_oversea_year.sync="currentItem.is_live_oversea_year"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="婚姻状况：" prop="married">
                        <el-select
                            v-model="currentItem.married"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option
                                v-for="item in CONFIG.MARRY_TYPE"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="是否办理过香港身份证："
                        prop="has_hk_id.has"
                        label-width="180px"
                    >
                        <el-radio-group
                            v-model="currentItem.has_hk_id.has"
                            @change="changeHKHas"
                        >
                            <el-radio label="Y">是</el-radio>
                            <el-radio label="N">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        v-if="currentItem.has_hk_id.has === 'Y'"
                        label="香港身份证号码："
                        prop="has_hk_id.info"
                    >
                        <!-- <el-input
                            v-model.trim="currentItem.has_hk_id.info"
                            clearable
                            auto-complete="off"
                            maxlength="25"
                        /> -->
                        <HkIdCardInput v-model="currentItem.has_hk_id.info" suffix-required></HkIdCardInput>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所在行业：" prop="industry">
                        <el-select
                            v-model="currentItem.industry"
                            placeholder="请选择"
                            clearable
                            @change="changeIndustry"
                        >
                            <el-option
                                v-for="item in CONFIG.INDUSTRY_TYPE"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="其他行业："
                        prop="industry_other_desc"
                        v-if="currentItem.industry === 28"
                    >
                        <el-input
                            v-model="currentItem.industry_other_desc"
                            placeholder="请输入其他行业"
                            clearable
                            auto-complete="off"
                            maxlength="30"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="证件类型："
                    >
                        <el-select
                            v-model="currentItem.certificates.passport.type"
                            placeholder="请选择"
                            @change="changeCertificateType"
                            clearable
                        >
                            <el-option
                                v-for="item in CONFIG.CERTIFICATE_TYPE"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                        <p class="certificates_info">
                            签约前12个月常居国内请勾选港澳行证，常居海外则勾选护照。
                        </p>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="证件号码："
                        :style="{
                            marginBottom:
                                currentItem.has_hk_id.has === 'Y'
                                    ? '18px'
                                    : '34px',
                        }"
                    >
                        <el-input
                            v-model="currentItem.certificates.passport.number"
                            clearable
                            maxlength="50"
                            placeholder="请输入证件号码"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="签发地："
                    >
                        <el-input
                            v-model="currentItem.certificates.passport.issue_at"
                            clearable
                            maxlength="50"
                            placeholder="请输入签发地"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="签发日期："
                    >
                        <el-date-picker
                            v-model="
                                currentItem.certificates.passport
                                    .issue_date_start_at
                            "
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="届满日期："
                    >
                        <el-date-picker
                            v-model="
                                currentItem.certificates.passport
                                    .issue_date_end_at
                            "
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="申请类型：" prop="application_type">
                        <el-select
                            v-model="currentItem.application_type"
                            placeholder="请选择"
                            clearable
                            @change="changeApplicationType"
                        >
                            <el-option
                                v-for="item in CONFIG.APPLY_TYPE"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <template v-if="currentItem.application_type === 1">
                    <el-col :span="12">
                        <el-form-item label="收入种类：" prop="type_of_income">
                            <el-select
                                v-model="currentItem.type_of_income"
                                placeholder="请选择"
                                clearable
                                multiple
                            >
                                <el-option
                                    v-for="item in CONFIG.INCOMING_TYPE"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
                <template v-if="currentItem.application_type === 2 || currentItem.application_type === 3">
                    <el-col :span="12">
                        <el-form-item
                            label="本科毕业年份："
                            prop="undergraduate_end_time"
                        >
                            <el-date-picker
                                v-model="currentItem.undergraduate_end_time"
                                value-format="yyyy"
                                type="year"
                                placeholder="请选择"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="本科毕业月份："
                            prop="undergraduate_graduation_month"
                        >
                        <el-select
                                v-model="currentItem.undergraduate_graduation_month"
                                placeholder="请选择"
                                clearable
                            >
                                <el-option
                                    v-for="item in 12"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工作年限：" prop="years_of_work">
                            <el-select
                                v-model="currentItem.years_of_work"
                                placeholder="请选择"
                                clearable
                                @change="changYearsOfWork"
                            >
                                <el-option
                                    v-for="item in CONFIG.WORK_YEAR_TYPE"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="本科就读院校名称："
                            :rules="{ required: true, message: '请输入匹配本科就读院校名称', trigger: 'change' }"
                            prop="college"
                        >
                            <el-select
                                v-model="currentItem.college"
                                filterable
                                allow-create
                                default-first-option
                                placeholder="请选择本科就读院校名称"
                                style="width: 100%;"
                            >
                                <el-option
                                    v-for="item in topSchoolList"
                                    :key="item.value"
                                    :value="item.id"
                                    :label="`${item.alias ? item.alias + (item.name ? ' / ' + item.name : '') : item.name }`"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-row :gutter="10">
                            <el-col :span="15">
                                <el-form-item
                                    label="就读地点："
                                    :rules="{ required: true, message: '请输入上课地点', trigger: 'blur' }"
                                    prop="school_address.country"
                                >
                                    <CountrySelect v-model="currentItem.school_address.country" placeholder="输入匹配国家及地区" @input="handleSelect" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="9">
                                <el-form-item
                                    class="form-item"
                                    v-if="currentItem.school_address.country.indexOf('中国') !== -1 || currentItem.school_address.country === ''"
                                    prop="school_address.area"
                                    :rules="{ required: true, message: '请选择城市', trigger: 'change' }"
                                >
                                    <CitySelect :country="currentItem.school_address.country" v-model="currentItem.school_address.area"  style="width: 100%;" />
                                </el-form-item>
                                <el-form-item
                                    class="form-item"
                                    prop="school_address.foreign"
                                    :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                                    v-else
                                >
                                    <el-input v-model="currentItem.school_address.foreign" clearable placeholder="请输入城市" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="主修科目：" prop="majors">
                            <el-select
                                v-model="currentItem.majors"
                                placeholder="请选择"
                                clearable
                            >
                                <el-option
                                    v-for="item in CONFIG.SUBJECT_TYPE"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="currentItem.majors == '其它 （请注明）'">
                        <el-form-item label="其他：" prop="majors_other">
                            <el-input
                            v-model="currentItem.majors_other"
                            clearable
                            maxlength="50"
                            placeholder="请输入"
                        />
                        </el-form-item>
                    </el-col>
                </template>
                    <template v-if="isApplicantShow">
                        <el-col :span="24">
                            <el-form-item class="form-item-title__w355" label="申请人是否拥有现时定居国家/地区的永久居留身份：" prop="have_permanent_residency">
                                <el-radio-group
                                    v-model="currentItem.have_permanent_residency"
                                >
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item class="form-item-title__w170"  label="申请人现正居在海外：" prop="is_currently_overseas">
                                <el-radio-group
                                    v-model="currentItem.is_currently_overseas"
                                >
                                    <el-radio :label="1">是</el-radio
                                    >
                                    <el-radio :label="0">否</el-radio
                                    >
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item class="form-item-title__w200" label="在定居国家/地区居留时间：" prop="residence_time">
                                <el-date-picker
                                    v-model="currentItem.residence_time"
                                    value-format="yyyy-MM"
                                    type="month"
                                    placeholder="请选择"
                                    style="width: 250px"
                                />
                            </el-form-item>
                        </el-col>
                    </template>

                <el-col :span="24">
                    <el-form-item label="相关说明1：" prop="declaration_first">
                        <el-radio-group
                            class="declaration_radio"
                            v-model="currentItem.declaration_first"
                            @change="(val) => changeDeclaration(val, 'declaration_first')"
                        >
                            <el-radio :label="0">本人未曾被拒绝签发签证或入境香港，同时也没有被遣返、遣送或要求离开香港的情况</el-radio>
                            <el-radio :label="1">本人曾经遭到过拒签或被拒绝入境香港的情况，具体日期和详细情况如下</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label=""
                        prop="declaration_first_desc"
                        v-if="currentItem.declaration_first === 1"
                    >
                        <el-input
                            type="textarea"
                            :rows="2"
                            maxlength="500"
                            placeholder="请输入具体日期和详细情况"
                            v-model="currentItem.declaration_first_desc"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="相关说明2：" prop="declaration_second">
                        <el-radio-group
                            class="declaration_radio"
                            v-model="currentItem.declaration_second"
                            @change="(val) =>changeDeclaration(val, 'declaration_second')"
                        >
                            <el-radio :label="0">本人从未因任何罪行或违法行为在香港或其他地方被定罪</el-radio>
                            <el-radio :label="1">本人曾因任何罪行或违法行为在香港或其他地方被定罪。有关日期和详细情况如下</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label=""
                        prop="declaration_second_desc"
                        v-if="currentItem.declaration_second === 1"
                    >
                        <el-input
                            type="textarea"
                            :rows="2"
                            maxlength="500"
                            placeholder="请输入具体日期和详细情况"
                            v-model="currentItem.declaration_second_desc"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import HkIdCardInput from '@/components/HkIdCardInput.vue'
import { saveHighTalentInfo, getTopSchool } from "../../../../../api/highTalent";
import CONFIG from "../../config";
export default {
    components: { HkIdCardInput },
    props: {
        // 主申人信息
        currentItem: {
            type: Object,
            default() {
                return {};
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isApplicantShow() {
            const { nationality, address: { country, area } } = this.currentItem
            const specialAreas = ['香港特别行政区', '澳门特别行政区', '台湾']
            return nationality === '中国-China' && (country !== '中国-China' || (area.length > 0 && specialAreas.includes(area[0])))
        }
    },
    created() {
        this.getTopSchool()
    },
    data() {
        return {
            CONFIG,
            topSchoolList: [],
            isTopSchoolLoading: false,
            rules: {
                username: [
                    { required: true, message: "请输入姓名", trigger: "blur" },
                ],
                "username_pinyin.family_name": [
                    {
                        required: true,
                        message: "请输入姓氏拼音",
                        trigger: "blur",
                    },
                ],
                "username_pinyin.given_name": [
                    {
                        required: true,
                        message: "请输入名称拼音",
                        trigger: "blur",
                    },
                ],
                birthday: [
                    {
                        required: true,
                        message: "请选择出生日期",
                        trigger: "change",
                    },
                ],
                birth_place_aboard: [
                    {
                        required: true,
                        message: "请选择国内/国外",
                        trigger: "change",
                    },
                ],
                "birth_place.area": [
                    {
                        required: true,
                        message: "请选择出生城市",
                        trigger: "change",
                    },
                ],
                "birth_place.foreign": [
                    { required: true, message: "请输入地址", trigger: "blur" },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                married: [
                    {
                        required: true,
                        message: "请选择婚姻状况",
                        trigger: "change",
                    },
                ],
                nationality: [
                    {
                        required: true,
                        message: "请输入国籍/地区",
                        trigger: "change",
                    },
                ],
                "has_hk_id.has": [
                    {
                        required: true,
                        message: "请选择是否办理过香港身份证",
                        trigger: "change",
                    },
                ],
                "has_hk_id.info": [
                    {
                        required: true,
                        trigger: "change",
                        validator: this.$rules.validate.hkmIdSuffix,
                    },
                ],
                industry: [
                    {
                        required: true,
                        message: "请选择所在行业",
                        trigger: "change",
                    },
                ],
                industry_other_desc: [
                    {
                        required: true,
                        message: "请输入其他行业",
                        trigger: "change",
                    },
                ],
                "certificates.passport.type": [
                    {
                        required: true,
                        message: "请选择证件类型",
                        trigger: "change",
                    },
                ],
                "certificates.passport.issue_at": [
                    {
                        required: true,
                        message: "请输入签发地",
                        trigger: "change",
                    },
                ],
                "certificates.passport.issue_date_start_at": [
                    {
                        required: true,
                        message: "请选择签发日期",
                        trigger: "change",
                    },
                ],
                "certificates.passport.issue_date_end_at": [
                    {
                        required: true,
                        message: "请选择届满日期",
                        trigger: "change",
                    },
                ],
                "certificates.passport.number": [
                    {
                        required: true,
                        message: "请输入证件号码",
                        trigger: "change",
                    },
                ],
                application_type: [
                    {
                        required: true,
                        message: "请选择申请类型",
                        trigger: "change",
                    },
                ],
                type_of_income: [
                    {
                        required:  this.$route.query.history ? false : true,
                        message: "请选择收入种类",
                        trigger: "change",
                    },
                ],
                years_of_work: [
                    {
                        required: true,
                        message: "请选择工作年限",
                        trigger: "change",
                    },
                ],
                declaration_first: [
                    {
                        required: true,
                        message: "请选择相关说明1",
                        trigger: "change",
                    },
                ],
                declaration_second: [
                    {
                        required: true,
                        message: "请选择相关说明2",
                        trigger: "change",
                    },
                ],
                declaration_first_desc: [
                    {
                        required: true,
                        message: "请输入具体日期和详细情况",
                        trigger: "change",
                    },
                ],
                declaration_second_desc: [
                    {
                        required: true,
                        message: "请输入具体日期和详细情况",
                        trigger: "change",
                    },
                ],
                undergraduate_end_time: [
                    {
                        required: true,
                        message: "请选择本科毕业年份",
                        trigger: "change",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "请输入国家",
                        trigger: "change",
                    }
                ],
                birth_place_detail: [
                    {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                    }
                ],
                have_permanent_residency: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    }
                ],
                is_currently_overseas: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    }
                ],
                residence_time: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    }
                ],
                majors: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    }
                ],
                majors_other: [
                    {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                    }
                ],
            },
        };
    },
    methods: {
        /**
         * @description 清除校验
         */
        async clearValidate(props) {
            await this.$nextTick;
            this.$refs.currentItem?.clearValidate(props);
        },
        /**
         * @description 切换出生国家
         */
        changeBirthCountry(val) {
            if (val === 0) {
                this.currentItem.birth_place.foreign = "";
            } else {
                this.currentItem.birth_place.area = [];
            }
            this.clearValidate(["birth_place_area", "birth_place.foreign"]);
        },
        /**
         * @description 香港身份证切换
         */
        changeHKHas() {
            this.currentItem.has_hk_id.info = "";
            this.clearValidate("has_hk_id.info");
        },
        /**
         * @description 证件类型切换
         */
        changeCertificateType(val) {
            this.currentItem.certificates.passport.issue_at = "";
            this.currentItem.certificates.passport.issue_date_start_at = "";
            this.currentItem.certificates.passport.issue_date_end_at = "";
            this.currentItem.certificates.passport.number = "";
            this.clearValidate([
                "certificates.passport.issue_at",
                "certificates.passport.issue_date_start_at",
                "certificates.passport.issue_date_end_at",
                "certificates.passport.number",
            ]);
        },
        /**
         * @description 所在行业切换
         */
        changeIndustry() {
            this.$set(this.currentItem, 'industry_other_desc', '')
            this.clearValidate("industry_other_desc");
        },
        /**
         * @description 申请类型切换
         */
        changeApplicationType() {
            [
                "type_of_income",
                "undergraduate_end_time",
                "years_of_work",
                "work_experience",
                "college"
            ].map((key) => {
                this.currentItem[key] = "";
                this.clearValidate(key);
            });
            this.currentItem.school_address = {
                country: '',
                area: [],
                foreign: '',
                details: '',
            }

        },
        /**
         * @description 工作年限切换
         */
        changYearsOfWork() {
            this.currentItem.work_experience = "";
            this.clearValidate("work_experience");
        },
        /**
         * @description 声明切换
         */
        changeDeclaration(val, type) {
            const key = type + "_desc";
            this.currentItem[key] = "";
            this.clearValidate(key);
        },
        /**
         * @description 校验当前步骤的表单
         * @param {type} 1保存 2下一步
         */
        async checkInfo() {
            // 点击的是下一步 校验成功后发送请求
            Promise.all([
                this.$refs.CurrentAddress.checkInfo(),
                this.$refs.currentItem.validate()
            ])
            .then(async () => {
                this.submit();
            })
            .catch((err) => {
                console.log(err);
                this.$utils.scrollIntoError(this);
            });
        },

        async submit() {
            try {
                this.$emit("update:loading", true);
                console.log(this.$route.query);
                const res = await saveHighTalentInfo({
                    ...this.currentItem,
                    role: 0, // 0:主申人；1：配偶；2：子女
                    crm_token: this.$route.query.crm_token,
                });
                if (res.code === 200) {
                    this.$message.success("保存成功");
                    await this.$utils.saveHighTalentStatus(
                        this.$route.params.id
                    );
                    // this.$emit('update:currentItem', this.currentItem)
                    this.$emit("update");
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.$emit("update:loading", false);
            }
        },

        // 获取高才学校
        async getTopSchool() {
            try {
                this.isTopSchoolLoading = true
                const { data, code} = await getTopSchool()
                if(code === 200) {
                    this.topSchoolList = data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isTopSchoolLoading = false
            }

        },
        // 输入匹配
        handleSelect(v) {
            this.currentItem.school_address.area = []
            this.currentItem.school_address.foreign = ''
        },
    },
};
</script>

<style lang="less" scoped>
.certificates_info {
    margin: 4px 0 0 0;
    font-size: 11px;
    line-height: 11px;
    color: #606266;
}
.declaration_radio {
    .el-radio {
        margin: 8px 0;
    }
}
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
.form-item-title__w355 {
    /deep/ .el-form-item__label {
        width: 355px !important;
    }
}
.form-item-title__w170 {
    /deep/ .el-form-item__label {
        width: 170px !important;
    }
}
.form-item-title__w200 {
    /deep/ .el-form-item__label {
        width: 200px !important;
    }
}
/deep/ .el-input--small .el-input__inner {
    height: 32px !important;
}
</style>

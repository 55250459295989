<!--
 * @Author: George
 * @Description: 个人信息表 - 配偶详情页
-->
<template>
    <!-- 回显信息 -->
    <div ref="userDetail" class="details-module common-part">
        <!-- 主申请人信息 -->
        <el-row :gutter="15">
            <el-col :span="12" id="subset_name">
                <span>配偶姓名：</span>
                <span>{{ currentItem.subset_name || "-" }}</span>
            </el-col>
            <el-col :span="12" id="used_name">
                <span>曾用名：</span>
                <span class="text-red">{{ currentItem.used_name || "-" }}</span>
            </el-col>
            <el-col :span="12" id="subset_name_pinyin">
                <span>姓名拼音：</span>
                <span>{{
                    currentItem.subset_name_pinyin.family_name +
                    " " +
                    currentItem.subset_name_pinyin.given_name
                }}</span>
            </el-col>
            <el-col :span="12" id="user_sex">
                <span>性别：</span>
                <span>{{
                    CONFIG.SEX_TYPE_REFLECT[currentItem.sex] || "-"
                }}</span>
            </el-col>
            <el-col :span="12" id="user_birthday">
                <span>出生日期：</span>
                <span>{{ currentItem.birthday }}</span>
                <!-- （<span class="text-red">{{
                        `${dataList[0].age}岁`
                    }}</span
                    >） -->
            </el-col>
            <el-col :span="12" id="user_nationality">
                <span>国籍：</span>
                <span>{{ currentItem.nationality || "-" }}</span>
            </el-col>
            <el-col :span="12" id="birth_place_aboard">
                <span>出生城市：</span>
                <span>{{
                    currentItem.birth_place_aboard === 0
                        ? currentItem.birth_place.area.join("") + currentItem.birth_place.details
                        : currentItem.birth_place.foreign
                }}</span>
            </el-col>
            <el-col :span="12" id="user_married">
                <span>婚姻状况：</span>
                <span>{{
                    CONFIG.MARRY_TYPE_REFLECT[currentItem.married] || "-"
                }}</span>
            </el-col>
            <el-col :span="12" id="spouse_isCohabitation">
                <span>是否与主申请人同住：</span>
                <span class="red">{{ currentItem.nows == 1 ? '是' : currentItem.nows == 0 ? '否' : '' }}</span>
            </el-col>
            <el-col v-if="currentItem.nows == 0"  :span="inChina ? 24 : 12" id="user_address">
                <span>现居住地址：</span>
                <span id="user_address_detail">{{ currentItem.address.country + '-' + (inChina ? (currentItem.address.area.join('') + currentItem.address.details) : currentItem.address.foreign) }}</span>
            </el-col>
            <el-col :span="12" id="user_address" v-if="!inChina">
                <span>是否居住满一年及以上：</span>
                <span>{{ currentItem.is_live_oversea_year === 1 ? '是' : '否' }}</span>
            </el-col>
            <el-col :span="12">
                <span>是否办理过香港身份证：</span>
                <span class="text-red">{{
                    CONFIG.HAS_HK_CARD_REFLECT[currentItem.has_hk_id.has] || "-"
                }}</span>
            </el-col>
            <el-col :span="12" id="user_hk_id_number">
                <span>香港身份证号码：</span>
                <span>{{
                    currentItem.has_hk_id.has === "Y"
                        ? currentItem.has_hk_id.info
                        : "-"
                }}</span>
            </el-col>
            <!-- <el-col :span="12" id="industry">
                <span>所在行业：</span>
                <span>{{
                    CONFIG.INDUSTRY_TYPE_REFLECT[currentItem.industry] || "-"
                }}</span>
            </el-col>
            <el-col
                :span="12"
                id="industry_other_desc"
                v-if="currentItem.industry === 28"
            >
                <span>其他行业：</span>
                <span>{{ currentItem.industry_other_desc || "-" }}</span>
            </el-col> -->
            <el-col :span="12" id="certificates.passport.type">
                <span>证件类型：</span>
                <span>{{
                    CONFIG.CERTIFICATE_TYPE_REFLECT[
                        currentItem.certificates.passport.type
                    ] || "-"
                }}</span>
            </el-col>
            <el-col :span="12" id="certificates.passport.number">
                <span>证件号码：</span>
                <span>{{ currentItem.certificates.passport.number }}</span>
            </el-col>
            <el-col :span="12" id="certificates.passport.issue_at">
                <span>签发地：</span>
                <span>{{ currentItem.certificates.passport.issue_at }}</span>
            </el-col>
            <el-col :span="12" id="certificates.passport.issue_date_start_at">
                <span>签发日期：</span>
                <span>{{
                    currentItem.certificates.passport.issue_date_start_at
                }}</span>
            </el-col>
            <el-col :span="12" id="certificates.passport.issue_date_end_at">
                <span>届满日期：</span>
                <span>{{
                    currentItem.certificates.passport.issue_date_end_at
                }}</span>
            </el-col>
            <template v-if="isApplicantShow">
                <el-col :span="24" id="have_permanent_residency">
                    <span>申请人是否拥有现时定居国家/地区的永久居留身份：</span>
                    <span>{{ currentItem.have_permanent_residency == 1 ? '是' : '否' }}</span>
                </el-col>
                <el-col :span="12" id="residence_time">
                    <span>在定居国家/地区居留时间：</span>
                    <span>{{ currentItem.residence_time || "-" }}</span>
                </el-col>
            </template>
            <el-col :span="12" id="residence_time">
                <span>在定居国家/地区：</span>
                <span>{{ currentItem.residence_address ? currentItem.residence_address.country : "-" }}</span>
            </el-col>
            <el-col :span="12" id="residence_time">
                <span>与主申请人关系：</span>
                <span>{{ currentItem.relation_desc || "-" }}</span>
            </el-col>
            <el-col :span="24" id="declaration_first" class="declaration">
                <span>相关说明1：</span>
                <div>
                    <p v-if="currentItem.declaration_first === 0">
                        本人未曾被拒绝签发签证或入境香港，同时也没有被遣返、遣送或要求离开香港的情况
                    </p>
                    <div v-if="currentItem.declaration_first === 1">
                        <p>
                            本人曾经遭到过拒签或被拒绝入境香港的情况，具体日期和详细情况如下：
                        </p>
                        <p>{{ currentItem.declaration_first_desc }}</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="24" id="declaration_second" class="declaration">
                <span>相关说明2：</span>
                <div>
                    <p v-if="currentItem.declaration_second === 0">
                        本人从未因任何罪行或违法行为在香港或其他地方被定罪
                    </p>
                    <div v-if="currentItem.declaration_second === 1">
                        <p>
                            本人曾因任何罪行或违法行为在香港或其他地方被定罪。有关日期和详细情况如下：
                        </p>
                        <p>{{ currentItem.declaration_second_desc }}</p>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import CONFIG from "../../../../config";

export default {
    props: {
        currentItem: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        inChina() {
            return !!(this.currentItem.address.country && this.currentItem.address.country.includes('中国'))
        },
        isApplicantShow() {
            const { nationality, address: { country, area } } = this.currentItem
            const specialAreas = ['香港特别行政区', '澳门特别行政区', '台湾']
            return nationality === '中国-China' && (country !== '中国-China' || (area.length > 0 && specialAreas.includes(area[0])))
        }
    },
    data() {
        return {
            CONFIG,
        };
    },
};
</script>

<style lang="less" scoped>
.declaration {
    align-items: flex-start !important;
}
</style>

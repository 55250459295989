<!--
 * @Author: filename
 * @Description: 高才申请人信息 - 工作经历编辑
-->
<template>
    <div class="user-container common-part">
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="140px"
            class="demo-ruleForm"
        >
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item label="用人单位：" prop="company_name">
                        <el-input
                            v-model="form.company_name"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="职业：" prop="career">
                        <el-input
                            v-model="form.career"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="界别：" prop="company_type">
                        <el-select
                            v-model="form.company_type"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option
                                v-for="item in CONFIG.BOUNDARY_TYPE"
                                :label="item.name"
                                :value="item.value"
                                :key="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        v-if="form.company_type === '其它 （请注明）'"
                        label="其它 （请注明）："
                        prop="company_type_other"
                    >
                        <el-input
                            v-model="form.company_type_other"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="入职年月：" prop="entry_time">
                        <el-date-picker
                            v-model="form.entry_time"
                            type="month"
                            value-format="yyyy-MM"
                            placeholder="请选择">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="离职年月：" :prop="form.work_to_now != 1 ? 'departure_time' : ''">
                        <el-row>
                            <el-col v-if="form.work_to_now != 1" :span="17">
                                <el-date-picker
                                    v-model="form.departure_time"
                                    type="month"
                                    value-format="yyyy-MM"
                                    placeholder="请选择">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="5">
                                <el-checkbox
                                    v-model="form.work_to_now"
                                    :style="{ 'margin-left': form.work_to_now == 0 ? '20px' : '0' }"
                                    label="至今"
                                    :true-label="1"
                                    :false-label="0"
                                    @change="endTimeNow"
                                >
                                    至今
                                </el-checkbox>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { addOrderWork, updateOrderWork } from "../../../../../api/highTalent"
import CONFIG from "../../config"
export default {
    props: {
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.form = JSON.parse(JSON.stringify(this.currentItem))
    },
    data() {
        return {
            CONFIG,
            rules: {
                company_name: [{required: true, message: "请输入", trigger: "blur"}],
                career: [{required: true, message: "请输入", trigger: "blur"}],
                company_type: [{required: true, message: "请选择", trigger: "change"}],
                company_type_other: [{required: true, message: "请输入", trigger: "blur"}],
                entry_time: [{required: true, message: "请选择", trigger: "change"}],
                departure_time: [{required: true, message: "请选择", trigger: "change"}],
            },
            form: {}
        }
    },
    methods: {
        /**
         * @description 校验
         */
         async checkInfo() {
            try {
                await this.$refs.form.validate()
                this.saveInfo()
            } catch (error) {
                this.$utils.scrollIntoError(this)
            }
        },
        /**
         * @description 保存
         */
        async saveInfo() {
            try {
                this.$emit("update:loading", true)
                if (this.form.id === 0) delete this.form.id
                const fn = this.form.id ? updateOrderWork : addOrderWork
                const { code, data } = await fn(this.form)
                if (code === 200) {
                    if (!this.form.id) this.form.id = data.id
                    this.$message.success("保存成功")
                    this.form.isEdit = false
                    this.$emit("update", this.form)
                }
            } finally {
                this.$emit("update:loading", false)
            }
        },
        /**
         * @description 至今选项
         */
        endTimeNow(e) {
            if (e) {
                this.form.departure_time = ''
            }
        },
    },
};
</script>

<style lang="less" scoped>
.certificates_info {
    margin: 4px 0 0 0;
    font-size: 11px;
    line-height: 11px;
    color: #606266;
}
.declaration_radio {
    .el-radio {
        margin: 8px 0;
    }
}
</style>

<!--
 * @Author: George
 * @Description: 高才家庭成员-子女信息
-->
<template>
    <div class="mt-20">
        <Container
            title="主申请人工作经历"
            padding="0"
            v-loading="isLoading"
        >
            <div slot="handle">
                <div class="handle-btn">
                    <el-button @click="addWork">新增</el-button>
                </div>
            </div>
            <div v-for="(item, i) in work" :key="i">
                <div class="title-box" :id="`work${i}`">
                    <span>工作（{{ i + 1 }}/{{ work.length }}）</span>
                    <div class="title-btn">
                        <!-- 显示状态下按钮 -->
                        <template v-if="!item.isEdit">
                            <el-popconfirm
                                v-if="i > 0"
                                placement="top"
                                title="确定删除？"
                                @confirm="del(i)"
                            >
                                <el-button slot="reference">删除</el-button>
                            </el-popconfirm>
                            &nbsp;
                            <el-button
                                type="primary"
                                @click="editInfo(item)"
                                >编辑</el-button
                            >
                        </template>
                        <!-- 编辑状态下按钮 -->
                        <template v-else>
                            <el-button
                                :loading="item.isLoading"
                                @click="cancel(i)"
                                >取消</el-button
                            >
                            <el-button
                                type="primary"
                                :loading="item.isLoading"
                                @click="checkInfo(i)"
                                >保存</el-button
                            >
                        </template>
                    </div>
                </div>
                <!-- 显示 -->
                <WorkDetail v-if="!item.isEdit" :currentItem="item" />
                <!-- 编辑 -->
                <EditWork
                    v-if="item.isEdit"
                    :loading.sync="item.isLoading"
                    v-loading="item.isLoading"
                    :ref="'work' + i"
                    :currentItem="item"
                    @update="updateWork($event, i)"
                />
            </div>
        </Container>
    </div>
</template>

<script>
import WorkDetail from "./C/WorkDetail.vue"
import EditWork from "./C/EditWork.vue"
import { deleteOrderWork } from "../../../../api/highTalent"

export default {
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        WorkDetail,
        EditWork,
    },
    data() {
        return {
            isLoading: false,
            work: [],
        };
    },
    created() {
        this.work = JSON.parse(JSON.stringify(this.info.works))
        this.setIsEdit()
    },
    methods: {
        isEditWork() {
            return !!this.work.find((item) => item.isEdit)
        },
        // set isEdit
        setIsEdit() {
            this.work.forEach((item, i) => {
                this.$set(this.work[i], "isEdit", false)
                this.$set(this.work[i], "isLoading", false)
            })
        },
        // 新增
        addWork() {
            if (this.isEditWork()) {
                this.$message.error("请先保存正在编辑或者新增的工作经历！")
                return
            }
            this.work.push({
                order_id: this.$route.params.id,
                company_name: '',
                career: '',
                company_type: '',
                company_type_other: '',
                entry_time: '',
                departure_time: '',
                isEdit: true,
                isLoading: false,
                work_to_now: 0,
            })
            this.anchorPoint()
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true
        },
        // 单个工作经历保存
        checkInfo(index) {
            this.$refs["work" + index][0]?.checkInfo()
        },
        // 取消
        cancel(index) {
            if (this.work[index].id || this.work[index].id == 0) {
                this.work[index].isEdit = false
            } else {
                this.work.splice(index, 1)
            }
        },
        // 更新
        updateWork(data, index) {
            this.work[index] = data
        },
        // 删除
        async del(index) {
            if (this.isEditWork()) {
                this.$message.error("请先保存正在编辑或者新增的工作经历！")
                return
            }
            try {
                this.isLoading = true
                if (this.work[index].id) {
                    const { code } = await deleteOrderWork({
                        id: this.work[index].id,
                    })
                    if (code === 200) {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                        })
                    }
                }
                this.work.splice(index, 1)

            } finally {
                this.isLoading = false
            }
        },
        // 新增锚点到新增相应的位置
        anchorPoint() {
            this.$nextTick(() => {
                const pageId = document.querySelector(
                    "#work" + (this.work.length - 1)
                );
                pageId.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                })
            })
        },
    },
}
</script>

<style lang="less" scoped>
.tooltip {
    display: inline-block;
    border-radius: 100%;
    background: #1296db;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border: 1px #377395 solid;
    // height: 100%;
}
.content {
    padding: 3px;
    div {
        margin: 10px 0;
    }
}
</style>

<!--
 * @Author: filename
 * @Description: 个人信息表 - 资产
-->
<template>
    <!-- 回显信息 -->
    <div ref="assetsDetail" class="details-module common-part">
        <el-row :gutter="15">
            <el-col :span="12">
                <span style="min-width: 50px;">货币：</span>
                <span>{{ currentItem.currency || "-" }}</span>
            </el-col>
            <el-col :span="12">
                <span style="min-width: 50px;">金额：</span>
                <span>{{ currentItem.amount_of_money || "-" }}</span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {

        }
    },
};
</script>

<style lang="less" scoped>
.details-list .details-module .el-row .el-col > span:first-of-type {
    min-width: 30px;
}
</style>
